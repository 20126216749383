<template src="./Admin.html"></template>

<script>
import AdminAcademic from './AdminAcademic/AdminAcademic.vue'
import AdminNetworking from './AdminNetworking/AdminNetworking.vue';
import AdminIdi from './AdminIdi/AdminIdi.vue';
import AdminEvents from './AdminEvents/AdminEvents.vue';

export default {
  name: 'admin',
  components: { AdminAcademic, AdminNetworking, AdminIdi, AdminEvents },
  data () {
    return {
      district: 'academic'
    }
  },
  methods: {
    setDistrict(district) {
      this.district = district;
    }
  },
  computed: {
    showDistrict() {
      return this.district;
    }
  }
}
</script>

<style lang="scss" scoped src="./Admin.scss">

</style>